import type {
  Address,
  Carrier,
  CooledDeliveryType,
  Medication,
  MedicineArticle,
  ParcelItem,
  PermissionGiven,
  ShipmentTypeEnum
} from '~/models'
import { addWeeks, isBefore } from 'date-fns'
import { query, where, getCountFromServer } from 'firebase/firestore'

export default function usePrintingValidation() {
  const { getMedicineForParcelItem } = useMedicines()
  const { collection, getDocument } = useFirebase()
  const { truncateString } = useHelpers()
  const { t } = useI18n()
  const {
    addresses,
    errors,
    loadingShipment,
    multipleDisputedParcels,
    parcels,
    preferredShipmentMethods,
    selectedParcel,
    userDetails,
    isActivePickupPoint
  } = storeToRefs(usePrintingStore())
  const { getUserAndAproData, getAddresses, getAppUserMedications } =
    useAppUsers()
  const { fetchDealsByUserId } = useDeals()
  const { zohoPreshipmentValidation } = useZoho()
  const { authorizationErrors, pushError, removeError } = usePrintingErrors()
  const { getMedicationAgreementById } = useMedicationAgreement()

  const preferences = ref<ShipmentTypeEnum[]>([])

  // Execute checks and clean up errors array
  async function parcelValidationCheck(): Promise<void> {
    if (!parcels.value[0]) return

    addresses.value = []
    await checkDealIsActive()
    await checkUserDetails()
    checkWelcomeGiftSent()
    checkAddress()
    handlePreferredShipmentMethods()

    // Call Zoho preshipmentvalidation function
    await zohoPreshipmentValidation(parcels.value[0].UserId)
    await checkIfPrescriptionNeededForParcel()

    await checkDisputedParcelStatus(parcels.value[0].UserId)

    const hasNoMedForATK = errors.value.filter(
      (error) => error.code === authorizationErrors.noMedForATK
    ).length

    const hasLatestPrescriptionOpen = errors.value.filter(
      (error) => error.code === authorizationErrors.latestPrescriptionOpen
    ).length

    // Remove error if no open prescriptions (checked in Zoho)
    if (hasNoMedForATK && !hasLatestPrescriptionOpen) {
      removeError(authorizationErrors.noMedForATK)
    }
    removeError(authorizationErrors.latestPrescriptionOpen)

    loadingShipment.value = false
  }

  async function checkDealIsActive(): Promise<void> {
    const dealSnapshot = await fetchDealsByUserId(parcels.value[0].UserId)

    if (!dealSnapshot.empty) {
      let dealIsActive = false

      if (
        dealSnapshot.docs[0].data().PharmacyStatus.toUpperCase() === 'ACTIEF'
      ) {
        dealIsActive = true
      }

      if (!dealIsActive) {
        pushError('activeDeal', t('printing.noActiveDeal'))
      }
    }
  }

  // Check if app user is missing birthdate and APRO patient id
  // Set app user address
  async function checkUserDetails(): Promise<void> {
    const userData = await getUserAndAproData(parcels.value[0].UserId)
    if (!userData) {
      pushError('noUserDetails', t('printing.noDetailsFound'))
      return
    }

    userDetails.value = userData

    if (
      userDetails.value?.ExternalUserData?.AproData?.PatientId ===
      t('appUser.noAPROnumberFound')
    ) {
      pushError('patientId', t('printing.noGuid'))
    }

    if (!userDetails.value?.ProfileDataMap?.BirthDate) {
      pushError('birthDate', t('printing.noDOB'))
    }

    addresses.value = getAddresses(userDetails.value)
  }

  // Check if welcomegift is sent based on ConfirmedWelcomeGiftSentAt date
  function checkWelcomeGiftSent(): void {
    if (!userDetails.value) return

    if (
      userDetails.value.ConfirmedWelcomeGift &&
      !userDetails.value.ConfirmedWelcomeGiftSentAt
    ) {
      pushError('giftNotSent', t('printing.giftNotSent'))
    }
  }

  const shipmentAddress = computed((): Address | undefined => {
    if (addresses.value.length === 0) {
      return undefined
    }

    const deliveryPreference = userDetails.value?.DeliveryPreference

    // Old version of the app does not use the delivery preference.
    // The address that is last in the list is to be used
    if (!deliveryPreference) {
      return addresses.value.at(-1)
    }

    // Current version of the app uses the delivery preference.
    // The first address is always the home address and the second the deviating delivery address)
    if (
      addresses.value.length > 1 &&
      deliveryPreference === 'deliveryAddress'
    ) {
      return addresses.value[1]
    }

    // return the home address for both delivery preference of homeAddress and pickupPoint
    // this is due to the fact that we can still choose a different delivery method other than a pickupPoint
    return addresses.value[0]
  })

  // Get housenumber and housenumber addition if present
  function getHouseNumber(): string | undefined {
    const maxLengthHouseNumber = 20

    const streetNumber = shipmentAddress.value?.StreetNumber
    const streetNumberAddition = shipmentAddress.value?.StreetNumberAddition

    if (!streetNumber) {
      throw new Error('Streetnumber not defined, cannot create label')
    }

    if (!streetNumberAddition) {
      return truncateString(streetNumber, maxLengthHouseNumber)
    }

    const houseNumber = `${streetNumber} ${streetNumberAddition}`

    return truncateString(houseNumber, maxLengthHouseNumber)
  }

  // Check if we have all required address data
  function checkAddress(): void {
    if (
      !shipmentAddress?.value?.City ||
      !shipmentAddress?.value?.PostalCode ||
      !shipmentAddress?.value?.StreetNumber ||
      !shipmentAddress?.value?.Street
    ) {
      pushError('address', t('printing.addressDetailsInvalid'))
    }
  }

  // Handle shipment preferences based on permissions given by app user
  async function handlePreferredShipmentMethods(): Promise<void> {
    preferredShipmentMethods.value = []
    preferences.value = []

    const deliveryPreference = userDetails.value?.DeliveryPreference
    const pickupPoint = userDetails.value?.PickupPoint

    const cooledDeliveryTypes: Set<CooledDeliveryType> =
      await getCooledDeliveryTypes()

    if (cooledDeliveryTypes.has('Full')) {
      handleFullCooledDeliveryPreference(cooledDeliveryTypes)
      return
    }

    if (deliveryPreference == 'pickupPoint' && pickupPoint) {
      if (!isActivePickupPoint.value) {
        pushError('inactiveLocation', t('printing.inactiveLocation'))
      }
      handlePickupPointPreferences(pickupPoint?.Carrier)
      preferredShipmentMethods.value = preferences.value
      return
    }

    const userPermissions = userDetails.value?.Permissions

    if (!userPermissions) {
      throw new Error('No user permissions found')
    }
    handleDeliveryPreferences(userPermissions)

    // Remove duplicate values from preferences array
    preferredShipmentMethods.value = [...new Set(preferences.value)]
  }

  function handleDeliveryPreferences(permissions: PermissionGiven[]) {
    for (const permission of permissions) {
      switch (permission.Id) {
        case 'mailbox':
          preferences.value.push('postNLMailbox', 'trunkrsMailbox')
          break
        case 'neighbours':
          let prefs: ShipmentTypeEnum[] = ['postNLDefault', 'trunkrsDefault']
          if (!permission.Given) {
            prefs = ['postNLHome']
          }
          preferences.value.push(...prefs)
          break
      }
    }
    if (!preferences.value.length) {
      // Set default preferences to PostNL Home
      preferences.value.push('postNLHome')
    }
  }

  function handlePickupPointPreferences(carrier?: Carrier) {
    switch (carrier) {
      case 'postnl':
        preferences.value.push('postNLPickup')
        break
      case 'budbee':
        preferences.value.push('budbeeBox')
        break
      default:
        throw new Error(`${carrier} is not a supported carrier`)
    }
  }

  // Iterate through parcel items and check if we have the required authorization
  async function checkIfPrescriptionNeededForParcel(): Promise<void> {
    if (!selectedParcel.value) return

    const userMedicines = await getAppUserMedications(
      selectedParcel.value.UserId
    )
    for (const parcelItem of selectedParcel.value.ParcelItems) {
      if (parcelItem.CancelledBy || !parcelItem.MedicineArticleRef) {
        continue
      }

      const currentArticle = await getDocument<MedicineArticle>(
        parcelItem.MedicineArticleRef?.path
      )

      if (!currentArticle?.ArticleNumber) continue

      const userMedicine = getUserMedicineForArticle(
        userMedicines,
        currentArticle
      )
      if (!userMedicine) {
        pushErrorForNoMedicineForATK()
        continue
      }

      if (skipGeneratingPrescription(userMedicine, parcelItem)) {
        continue
      }

      handleAuthorizationRange(parcelItem, userMedicine)
    }
  }

  // Check if the user contains more than 1 disputed parcel
  // This will show danger notice and blocks mailbox delivery options
  async function checkDisputedParcelStatus(UserId: string) {
    const oneYearAgo = new Date()
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1)

    const disputedParcels = query(
      collection('Parcels'),
      where('UserId', '==', UserId),
      where('CurrentStatus', '==', 'disputed'),
      where('Created', '>=', oneYearAgo)
    )

    const snapshot = await getCountFromServer(disputedParcels)
    if (snapshot.data().count > 1) {
      multipleDisputedParcels.value = true
    }
  }

  // VPKWGM === R means a prescription is required in order to hand out the medicine.
  // So check if the medicine needs a prescription to be handed out
  // Check if we have a MedicineProduct ref. If we do, we have a link to the medicine in the Medicine collection and thus a link to the ATC code.
  // If we don't have a RemainingEndDate, we can't determine if the med needs authorization.

  // To summarize:
  // If one of the following:
  // No prescription manditory for med
  // We can't create a link to Product
  // Don't have a authorization date

  // We don't need to generate a prescription
  function skipGeneratingPrescription(
    userMedicine: Medication,
    parcelItem: ParcelItem
  ): boolean {
    if (
      userMedicine.ProductInfoMap?.VPKWGM !== 'R' ||
      !parcelItem.MedicineProductRef ||
      !parcelItem.MedicationAgreementId
    ) {
      return true
    }
    return false
  }

  function pushErrorForNoMedicineForATK(): void {
    pushError(authorizationErrors.noMedForATK, t('parcel.noMedForAtk'))
  }

  function pushErrorForAuthorizationNeeded(): void {
    pushError(
      authorizationErrors.authorizationNeeded,
      t('parcel.prescriptionNeeded')
    )
  }

  // Find and return user medicine for a given article
  function getUserMedicineForArticle(
    userMedicines: Medication[],
    currentArticle: MedicineArticle
  ): Medication | undefined {
    return userMedicines.find(
      (userMed) =>
        userMed.ProductInfoMap?.ATKode ===
        currentArticle?.ArticleNumber.toString()
    )
  }

  // Check if authorization is needed based on date ranges
  async function handleAuthorizationRange(
    parcelItem: ParcelItem
  ): Promise<void> {
    let medicationAgreement = undefined

    if (parcelItem.MedicationAgreementId) {
      medicationAgreement = await getMedicationAgreementById(
        parcelItem.MedicationAgreementId
      )
    }

    const authorizationDate = medicationAgreement?.AuthorisationDate

    if (!authorizationDate) return

    const authorizationRange = await getAuthorizationRangeInWeeks(parcelItem)
    const formattedAuthorizationDate = authorizationDate.toDate()

    const authorizationDateRangeToCheck = addWeeks(
      Date.now(),
      authorizationRange
    )

    if (isBefore(formattedAuthorizationDate, authorizationDateRangeToCheck)) {
      pushErrorForAuthorizationNeeded()
    }
  }

  // Check if the parcel item is an opiate by ATC
  async function getAuthorizationRangeInWeeks(
    parcelItem: ParcelItem
  ): Promise<number> {
    const opiateAtcs = ['A03', 'N02', 'N05', 'N06B', 'N05CD', 'N05CF']
    const medicine = await getMedicineForParcelItem(parcelItem)

    let authorizationRange = 2

    if (medicine?.ATC && opiateAtcs.includes(medicine?.ATC)) {
      authorizationRange = 4
    }

    return authorizationRange
  }

  // Remove authorization errors from errors array
  // This would be used every time a new parcel is selected
  function resetAuthorizationErrors(): void {
    removeError(authorizationErrors.authorizationNeeded)
    removeError(authorizationErrors.noMedForATK)
    removeError(authorizationErrors.latestPrescriptionOpen)
  }

  async function getCooledDeliveryTypes(): Promise<Set<CooledDeliveryType>> {
    const cooledDeliveryTypes = new Set<CooledDeliveryType>()

    const parcelItems =
      selectedParcel.value?.ParcelItems.filter(
        ({ CancelledBy }) => !CancelledBy
      ) ?? []

    const articles = await Promise.all(
      parcelItems.map(async ({ MedicineArticleRef }) => {
        if (MedicineArticleRef) {
          return await getDocument<MedicineArticle>(MedicineArticleRef.path)
        }
        return undefined
      })
    )

    for (const article of articles) {
      if (article?.IsCooledDelivery) {
        cooledDeliveryTypes.add(article.IsCooledDelivery)
      } else {
        cooledDeliveryTypes.add('None')
      }
    }

    return cooledDeliveryTypes
  }

  function handleFullCooledDeliveryPreference(
    cooledDeliveryTypes: Set<CooledDeliveryType>
  ) {
    if (cooledDeliveryTypes.has('None')) {
      preferredShipmentMethods.value.push('ampCooledUncooled')
    } else {
      preferredShipmentMethods.value.push('ampCooled')
    }
  }

  return {
    resetAuthorizationErrors,
    getHouseNumber,
    parcelValidationCheck,
    shipmentAddress
  }
}
